var initEvent = function initEvent(cos) {
  var listeners = {};

  var getList = function getList(action) {
    !listeners[action] && (listeners[action] = []);
    return listeners[action];
  };

  cos.on = function (action, callback) {
    if (action === 'task-list-update') {
      console.warn('warning: Event "' + action + '" has been deprecated. Please use "list-update" instead.');
    }

    getList(action).push(callback);
  };

  cos.off = function (action, callback) {
    var list = getList(action);

    for (var i = list.length - 1; i >= 0; i--) {
      callback === list[i] && list.splice(i, 1);
    }
  };

  cos.emit = function (action, data) {
    var list = getList(action).map(function (cb) {
      return cb;
    });

    for (var i = 0; i < list.length; i++) {
      list[i](data);
    }
  };
};

var EventProxy = function EventProxy() {
  initEvent(this);
};

module.exports.init = initEvent;
module.exports.EventProxy = EventProxy;